<template>
<!--村村通户内验收查询-->
  <div>
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
        <el-form style="width:100%"  :inline="true" >
          <el-form-item label="工程编号/名称:" style="padding:0px"> <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.searchText"
          ></el-input></el-form-item>
        
         
          <span style="margin-right: 20px"></span>
          <el-form-item label="安装施工队:" style="padding:0px">
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.install"
          ></el-input>
          </el-form-item>
         
          
          <span style="margin-right: 20px"></span>
          <el-form-item label="现场管理员:" style="padding:0px">
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.siteManager"
          ></el-input></el-form-item>
         <el-form-item label="验收人：" style="padding:0px">
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.acceptUser"
          ></el-input></el-form-item>
          
          <span style="margin-right: 20px"></span>
          <el-form-item label="户名/表编号/地址/身份证:" style="padding:0px">
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.searchUser"
          ></el-input></el-form-item>
         
         
          <span style="margin-right: 20px"></span>
          <el-form-item label="验收结果:" style="padding:0px">
           <el-select
            v-model="searchBox.result"
            clearable
            placeholder="请选择"
            size="small"
            multiple
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select></el-form-item>
          
         
          
          <span style="margin-right: 20px;"></span>
          <el-form-item label="验收时间:" style="padding:0px">
           <el-date-picker
              size="small"
              v-model="searchBox.time"
              type="datetimerange"
              style="width: 400px"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="选择验收时间"
            >
            </el-date-picker></el-form-item>
          
           
            <span style="margin-right:20px"></span>
            <el-form-item label="" style="padding:0px" label-width="0px">
              <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="search"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="resetData"
              >重置</el-button>
            </el-form-item>
          
          </el-form>
        </div>
        <div class="table">
          <div style="display: flex">
            <el-button class="SearchBtn" size="small" @click="accomplish"
            v-if="$anthButtons.getButtonAuth('cctyswc')"  
              >验收完成</el-button
            >
            <el-button class="SearchBtn" size="small" @click="deriveExcel"
              >导出</el-button
            >
          </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 460px)"
            :stripe="true"
            @sort-change="sort_change"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="install"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="realname"
              label="户名"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="phone"
              label="联系电话"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="idNumber"
              label="身份证号"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="address"
              label="地址"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="gradeNumber"
              label="表编号"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="contractCode"
              label="表具信息"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div
                  style="cursor: pointer; color: #409eff"
                  @click="chartDetil(row)"
                >
                  查看
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="contractCode"
              label="验收图片"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div
                 v-if="row.workVillageAcceptSubList && row.workVillageAcceptSubList.length >0 "
                  style="cursor: pointer; color: #409eff"
                  @click="pictureDetil(row)"
                >
                  查看
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="contractCode"
              label="当前验收结果"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <span
                 v-if="row.workVillageAcceptSubList && row.workVillageAcceptSubList.length >0 "
                  style="cursor: pointer; color: #409eff"
                  @click="acceptance(row)"
                >
                  {{
                     row.workVillageAcceptSubList[0].result == 1
                      ? "合格"
                      : row.workVillageAcceptSubList[0].result == 2
                      ? "不合格"
                      : "未出结果"
                  }}
                </span>
                <span v-else>未出结果</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="acceptUser"
              label="当前验收人"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }" v-if="row.workVillageAcceptSubList && row.workVillageAcceptSubList.length >0 ">
                {{ row.workVillageAcceptSubList[0].acceptUser}}
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="验收时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"
            >
            <template slot-scope="{ row }" v-if="row.workVillageAcceptSubList && row.workVillageAcceptSubList.length >0 ">
                {{ row.workVillageAcceptSubList[0].createTime}}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="del(row)"
                  v-if="$anthButtons.getButtonAuth('cctsc')"  
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  <!-- 验收信息 -->
    <el-dialog
      title="验收信息"
      :visible.sync="acceptanceDialog"
      width="600px"
      :close-on-click-modal="false"
    >
      <div>
        <el-dialog
          :visible.sync="examineDialog"
          width="600px"
          append-to-body
          :close-on-click-modal="false"
        >
          <el-form
            ref="project"
            v-if="examineDialog"
            :model="project"
            label-width="120px"
            size="mini"
          >
            <div style="max-height: 600px; overflow: auto">
              <p class="detil-code">整改通知</p>
              <div style="padding: 10px">
                <div class="item-list" style="margin-bottom: 10px">
                  整改原因： {{ rectificationInof.cause || "-" }}
                </div>
                <div class="item-list">
                  <div style="margin-bottom: 10px">不合格照片：</div>
                  <div>
                    <el-image
                      v-if="rectificationInof.img"
                      style="width: 100px; height: 100px"
                      :src="rectificationInof.img.split(',')[0]"
                      :preview-src-list="rectificationInof.img.split(',')"
                    >
                    </el-image>
                    <span style="margin-left: 10px" v-if="rectificationInof.img"
                      >共{{ rectificationInof.img.split(",").length }}张</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div style="flex: 1" >
                <p class="detil-code">整改处理</p>
                <div style="padding: 10px">
                  <el-row  >
                  <el-col :span="24">
                    <el-form-item label="处理结果：" prop="positionCode" >
                      {{ state[rectificationInof.state] }}
                    </el-form-item>
                </el-col>
                </el-row>
                    <el-row  v-if="rectificationInof.state == 2">
                  <el-col :span="24">
                    <el-form-item label="相关证据：" prop="positionCode" >
                    <el-image
                      v-if="rectificationInof.explainImg"
                      style="width: 100px; height: 100px"
                      :src="rectificationInof.explainImg.split(',')[0]"
                      :preview-src-list="rectificationInof.explainImg.split(',')"
                    >
                    </el-image>
                    <span style="margin-left: 10px" v-if="rectificationInof.explainImg"
                      >共{{ rectificationInof.explainImg.split(",").length }}张</span
                    >
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row  v-if="rectificationInof.teams">
                  <el-col :span="24">
                    <el-form-item label="施工队：" prop="positionCode" >
                      {{ rectificationInof.teams.join('；') }}
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row v-if="rectificationInof.updateBy">
                  <el-col :span="24">
                    <el-form-item label="操作人：" prop="positionCode" >
                      {{ rectificationInof.updateBy }}
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row v-if="rectificationInof.updateTime ">
                  <el-col :span="24">
                    <el-form-item label="操作时间：" prop="positionCode" >
                      {{ rectificationInof.updateTime }}
                    </el-form-item>
                </el-col>
                </el-row>
                </div>
              </div>
            <div>
             <div style="max-height: 600px; overflow: auto" v-if="rectificationInof.state != 2 && rectificationInof.workInspectionSgdSubsetList.length>0">
          <p class="detil-code">整改情况</p>
          <div v-for="(item,index) in rectificationInof.workInspectionSgdSubsetList" :key="index">
            <el-row>
            <el-col :span="24">
              <el-form-item label="整改施工队：" prop="positionCode" >
                {{ item.workTeamName }}
              </el-form-item>
           </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" v-if="item.laterImg">
              <el-form-item
                label="整改后情况："
                prop="positionCode"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item.laterImg.split(',')[0]"
                  :preview-src-list="item.laterImg.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ item.laterImg.split(',').length }}张</span
                >
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="24">
              <el-form-item label="整改说明：" prop="positionCode" >
                {{ item.explain }}
              </el-form-item>
            </el-col>
          </el-row>
          </div>
        </div>
              <!-- <div
                v-else-if="rectificationInof.state == 2"
                style="
                  padding: 10px;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                "
              >
                <div style="max-height: 600px; overflow: auto">
              <div style="padding: 10px">
                <div class="item-list">
                  <div style="margin-bottom: 10px">处理结果：不整改</div>
                </div>
                <div class="item-list">
                  <div style="margin-bottom: 10px">相关证据：</div>
                  <div>
                    <el-image
                      v-if="rectificationInof.explainImg"
                      style="width: 100px; height: 100px"
                      :src="rectificationInof.explainImg.split(',')[0]"
                      :preview-src-list="rectificationInof.explainImg.split(',')"
                    >
                    </el-image>
                    <span style="margin-left: 10px" v-if="rectificationInof.explainImg"
                      >共{{ rectificationInof.explainImg.split(",").length }}张</span
                    >
                  </div>
                </div>
              </div>
            </div>
              </div> 
              <div v-else style="padding: 20px 10px">暂无整改信息</div>-->
            </div>
          </el-form>
        </el-dialog>
        <el-table
          v-if="acceptanceDialog"
          :data="acceptanceTable"
          border
          height="400px"
          style="width: 100%"
        >
          <el-table-column
            prop="acceptDate"
            align="center"
            width="160px"
            label="验收时间"
          >
          </el-table-column>
          <el-table-column prop="result" align="center" label="整改结果">
            <template slot-scope="{ row }">
              <span>
                {{
                  row.result == 1
                    ? "合格"
                    : row.result == 2
                    ? "不合格"
                    : "未整改"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="acceptUser" align="center" label="验收人">
          </el-table-column>

          <el-table-column align="center" label="整改情况">
            <template slot-scope="{ row }">
              <span
                style="cursor: pointer; color: #409eff"
                @click="examine(row)"
                v-if="row.result == 2"
              >
                查看
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 导出 -->
    <el-dialog
      title="导出"
      :visible.sync="deriveDialog"
      width="25%"
      :close-on-click-modal="false"
    >
      <el-form ref="project" :model="project" size="mini" :rules="rule">
        <el-row>
          <el-col :span="24">
            <el-form-item label="工程名称：" prop="workId">
              <el-select
                v-model="project.workId"
                clearable filterable
                placeholder="请选择"
                size="small"
                @change="getProjectName"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="验收结果" prop="state">
              <el-checkbox-group v-model="project.state">
                <el-checkbox label="1">合格</el-checkbox>
                <el-checkbox label="2">不合格</el-checkbox>
                <el-checkbox label="3">未验收</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="导出格式" >
              <el-radio-group v-model="project.gcExcel">
                <el-radio :label="0">带图片</el-radio>
                <el-radio :label="1">文本</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button
          type="primary"
          class="SearchBtn"
          size="small"
          @click="exportDate"
          >导出</el-button
        >
        <el-row v-if="showMsg">
          <el-col :span="24">
            <el-form-item label="本次导出汇总：" prop="positionCode">
              <div style="color: red">
                共导出{{getTotal(project.state,objData)}}条
                <span v-if="project.state.findIndex(el => el == 1) >= 0">，合格{{ objData.qualified || 0}}条</span>
                <span v-if="project.state.findIndex(el => el == 2) >= 0">，不合格{{ objData.noQualified || 0 }}条</span>
                <span v-if="project.state.findIndex(el => el == 3) >= 0">，未验收{{  objData.noAccept || 0}}条</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 表具信息 -->
    <el-dialog
      title="表具信息"
      :visible.sync="chartDialog"
      width="45%"
      :close-on-click-modal="false"
      :close="closeprojectDesc"
    >
      <el-form ref="project" :model="projectDesc" size="mini">
        <el-row>
          <el-col :span="8">
            <el-form-item label="表编号：" prop="positionCode">
              {{ projectDesc.gradeNumber }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="燃气表类型：" prop="positionCode">
              {{ projectDesc.typeName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="表向：" prop="positionCode">
              {{ projectDesc.directionName }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="燃气表厂家：" prop="positionCode">
              {{ projectDesc.venderName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item label="表封号：" prop="positionCode">
                {{ projectDesc.titles }}
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item label="编号：" prop="positionCode">
                {{ projectDesc.number }}
              </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 验收图片 -->
    <el-dialog
      title="验收图片"
      :visible.sync="pictureDialog"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form ref="imgproject" :model="imgproject" size="mini">
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="户内管道照片："
              prop="positionCode"
              label-width="180px"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="pipeImgresult[0]"
                :preview-src-list="pipeImgresult"
              >
              </el-image>
              <span style="margin-left: 10px"
                >共{{ pipeImgresult.length }}张</span
              >
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="燃气表照片："
              prop="positionCode"
              label-width="180px"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="gasImgresult[0]"
                :preview-src-list="gasImgresult"
              >
              </el-image>
              <span style="margin-left: 10px"
                >共{{ gasImgresult.length }}张</span
              >
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="验收单照片："
              prop="positionCode"
              label-width="180px"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="acceptImgresult[0]"
                :preview-src-list="acceptImgresult"
              >
              </el-image>
              <span style="margin-left: 10px"
                >共{{ acceptImgresult.length }}张</span
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!--村村通验收完成 -->
    <el-dialog
      title="村村通验收完成"
      :visible.sync="institutionalDialog"
      width="500px"
      :close-on-click-modal="false"
      @close="clearAll"
    >
      <el-form ref="project1" :model="project" size="mini" :rules="ysrules">
        <el-row>
          <el-col :span="17">
            <el-form-item label="工程名称：" prop="workId">
              <el-select
                v-model="project.workId"
                clearable filterable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in projectListtwo"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="getProjectStatus()"
              >获取验收情况</el-button
            >
          </el-col>
        </el-row>

        <el-row v-if="JSON.stringify(objData.total)">
          <el-col :span="16">
            <el-form-item label="验收情况说明：" prop="positionCode">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="objData.pipeTotal">
          <el-col :span="24">
            <el-form-item label="验收立管情况：" prop="positionCode">
              总数量 {{ objData.pipeTotal }}，合格数量{{
                objData.pipeQualified
              }}，不合格数量{{ objData.pipeNoQualified }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="JSON.stringify(objData.total)">
          <el-col :span="24">
            <el-form-item label="验收集体户情况：" prop="positionCode">
              总数量 {{ objData.total }}，合格数量{{
                objData.qualified
              }}，不合格数量{{ objData.noQualified }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="objData.total == 0 || objData.total != objData.qualified">
          <el-col :span="16">
            <div style="color: red">当前验收情况，未达到验收完成标准</div>
          </el-col>
        </el-row>
        <el-button
          v-if="
            JSON.stringify(objData.total) &&
            objData.total != 0 &&
            objData.total == objData.qualified
          "
          type="primary"
          class="SearchBtn"
          size="small"
          @click="editstatus()"
          >验收完成</el-button
        >
        <el-button v-else class="SearchBtn" size="small" @click="clearAll"
          >关闭</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getVillage,
  prepare_getWorkProject,
  indoor_getAccept,
  excelVillage,
  indoor_getExcels,
  indoor_finishAccept,
  excelVillageNoImg
} from "@/RequestPort/intranet";
import { exportMethod } from "./../../common/js/exportExcel.js";
import getNowFormatDate from "./../../common/js/nowDate.js";
import axiosApi from "@/apis/checkOuter/index.js";
import {delVillage} from "@/RequestPort/cost/cost";
export default {
  name: "lockout",
  data() {
    return {
      status: "12",
      activeName: "1",
      
      project: {
        flag: 3,
        state: [],
        gcExcel:1,
      },
      projectDesc:{},
      state: {
        3: "已整改",
        2: "不整改",
        1: "进行整改",
        0: "未整改",
      },
      acceptImgresult: [],
      pipeImgresult: [],
      gasImgresult: [],
      searchBox: {
        current: 1,
        size: 100,
        name: "",
        result: [],
        searchUser:"",
        time:[],startDate:'',endDate:''
      },
      total: 0,
      deriveDialog: false,
      chartDialog: false,
      pictureDialog: false,
      examineDialog: false,
      typeList: [
        {
          label: "合格",
          value: 1,
        },
        {
          label: "不合格",
          value: 2,
        },
        {
          label: "未出结果",
          value: 3,
        },
      ],
      tableData: [],
      acceptanceTable: [],
      // 验收完成
      institutionalDialog: false,
      // 工程名称
      acceptanceDialog: false,
      // 导出下拉
      projectList: [],
      objData: {},
      imgproject: {},
      rule: {
        workId: {
          required: true,
          message: "请选择工程名称",
          trigger: "change",
        },
        state: {
          required: true,
          message: "请选择验收结果",
          trigger: "change",
        },
      },
      showMsg: 0,
      rectificationInof: {},
      rectificationTable: [],
      // 验收下拉
      projectListtwo:[],
      ysrules:{workId: {
          required: true,
          message: "请选择工程名称",
          trigger: "change",
        }},
        
    };
  },
  mounted() {
    // console.log(this.$refs.SearchBar.offsetHeight);
    this.loadList(this.searchBox);
    this.getOption();
  },
  methods: {
    closeprojectDesc(){
      this.projectDesc = {}
    },
    sort_change(column){
      if(column.order=='ascending'){
        this.searchBox.sortType = 'asc';
      }else{
        this.searchBox.sortType = 'desc';
      }
      this.loadList(this.searchBox);
    },
    getTotal(state,objData){
      let total=0
      if(state.findIndex(el => el == 1) >= 0){
        total+=objData.qualified 
      }
      if(state.findIndex(el => el == 2) >= 0){
        total+=objData.noQualified 
      }
      if(state.findIndex(el => el == 3) >= 0){
        total+=objData.noAccept 
      }
      return total      
    },
    // 取出工程名称导出
    getProjectName(val) {
      this.projectName = this.projectList.find(
        (el) => el.id == val
      ).projectName;
    },
    async examine(row) {
      let obj = {
        id: row.id,
        source: 6,
      };
      try {
        let { data } = await axiosApi.getIndoorRectify(obj);
        if (data.length) {
          console.log(data);
          this.rectificationInof = data[0];
          this.rectificationTable = data[0].workInspectionSgdSubsetList;
        }
      } catch (error) {}
      this.examineDialog = true;
    },
    // 导出
    exportDate() {
      this.$refs["project"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          if(this.project.gcExcel != 1){
            excelVillage(this.project).then((res) => {
              loading.close();
              indoor_getExcels(this.project).then((res) => {
                this.objData = res.data;
                this.showMsg = 1;
              });
              if (res) {
                const fileName =
                  this.projectName + "_村村通集体户内验收_" + getNowFormatDate();
                exportMethod(res, fileName);
              }
            });
          } else {
            excelVillageNoImg(this.project).then((res) => {
              loading.close();
              indoor_getExcels(this.project).then((res) => {
                this.objData = res.data;
                this.showMsg = 1;
              });
              if (res) {
                const fileName =
                  this.projectName + "_村村通集体户内验收_" + getNowFormatDate();
                exportMethod(res, fileName);
              }
            });
          }
        }
      });
    },
    chartDetil(row) {
      console.log("表具弹窗");
      this.chartDialog = !this.chartDialog;
      this.projectDesc = row;
    },
    // 获取工程名称
    getOption() {
      // 6 村村通户内验收查询 改 验收传11
      prepare_getWorkProject({ flag: 11}).then((res) => {
        this.projectListtwo = res.data;
      });
      // 6 村村通户内验收查询
      prepare_getWorkProject({ flag: 6}).then((res) => {
        this.projectList = res.data;
      });
    },
    pictureDetil(row) {
      console.log("图片弹窗");
      this.pictureDialog = !this.pictureDialog;
      if ( row.workVillageAcceptSubList[0].acceptImg) {
        this.acceptImgresult = row.workVillageAcceptSubList[0].acceptImg.split(",");
      }
      if (row.workVillageAcceptSubList[0].pipeImg) {
        this.pipeImgresult = row.workVillageAcceptSubList[0].pipeImg.split(",");
      }
      if (row.workVillageAcceptSubList[0].gasImg) {
        this.gasImgresult = row.workVillageAcceptSubList[0].gasImg.split(",");
      }
    },
    acceptance(row) {
      this.acceptanceTable = row.workVillageAcceptSubList;
      this.acceptanceDialog = true;
    },
    clearAll() {
      this.project = {
        workId: "",
        flag: 3,
        state: [],
      };
      this.objData = {};
      this.institutionalDialog = false;
    },
    // 验收详情
    getProjectStatus() {
       this.$refs["project1"].validate((valid) => {
        if (valid) {
          indoor_getAccept({ workId: this.project.workId, flag: 1 }).then((res) => {
            if (res.code == 200) {
              this.objData = res.data;
            }
          });
        }
       })
    },
    // 验收完成
    editstatus() {
      indoor_finishAccept({ workId: this.project.workId, flag: 1 }).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("验收完成");
            this.loadList(this.searchBox);
            this.institutionalDialog = false
            prepare_getWorkProject({ flag: 11}).then((res) => {
              this.projectListtwo = res.data;
            })
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 打开验收弹窗
    accomplish() {
      console.log("验收完成");

      this.institutionalDialog = true;
    },
    // 导出弹窗
    deriveExcel() {
      console.log("导出");
      if (this.$refs.project) {
        this.$refs.project.clearValidate();
      }
      this.showMsg = "";
      this.deriveDialog = !this.deriveDialog;
      this.gcExcel = null
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    loadList(obj) {
      if(obj.time&&obj.time.length>0){
        obj.startDate = obj.time[0]
        obj.endDate = obj.time[1]
      }else{
         obj.startDate = ''
         obj.endDate = ''
      }
      getVillage(obj).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    resetData() {
      this.searchBox = {
        current: 1,
        size: 100,
        searchText: "",
        install: "",
        siteManager: "",
        userName: "",
        searchUser:"",
        time:[]
      };
      this.loadList(this.searchBox);
    },
    del(row){
      this.$confirm('确认删除该条用户信息，包括验收结果信息','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type:'warning'
      }).then(()=>{
        //确定后删除
        delVillage(row.id).then(res=>{
          if(res.code==200){
            this.$message.success(res.msg)
            this.resetData()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
</style>